@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap');

html {
  box-sizing: border-box;
  height: 100%;
}

*,
*::before,
*::after {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
font,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-weight: inherit;
  font-style: inherit;
  font-size: 100%;
  font-family: inherit;
  vertical-align: baseline;
}

img {
  max-width: 100%;
  display: block;
}

a {
  text-decoration: none;
  color: inherit;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

i {
  margin: 0;
  padding: 0;
}

p {
  margin: 0;
  padding: 0;
}

body {
  height: 100%;
  display: flex;
  flex-direction: column;
}

main {
  flex: 1 0 auto;
}

footer {
  flex-shrink: 0;
}

.header {
  position: absolute;
  top: 0;
  z-index: 3;
  width: 100%;
  max-width: 100%;
}

.header-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
}

.header-logo {
  display: flex;
  align-items: center;
}

.header-logo__photo {
  display: block;
  width: 60px;
  height: 60px;
}

.header-logo__img {
  width: 100%;
  height: 100%;
}

.header-logo__text {
  display: flex;
  font-size: 30px;
  font-weight: 700;
  padding-left: 10px;
  color: #1594d1;
}

.header-logo__text span {
  color: #99c1df;
}

.header-phone {
  display: flex;
  width: 20%;
  margin-top: -8px;
}

.header-phone__text {
  display: inline-block;
  margin-left: 10px;
}

.header-content {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.header-menus {
  display: flex;
  margin: 0 30px;
}

.header-menu {
  padding: 0 10px;
  color: #545454;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 14px;
}

.header1 {
  position: fixed;
  width: 100%;
  box-shadow: -3px 1px 5px 1px #8f8c8c;
  background-color: #f7f7f7;
  z-index: 999;
}

.phone-menu {
  display: none;
}

.title {
  font-family: "Montserrat";
  text-transform: uppercase;
  font-weight: 600;
  font-size: 25px;
  color: #1594d1;
  position: relative;
  padding-left: 15px;
  width: 90%;
  max-width: 90%;
  margin-left: auto;
  margin-right: auto;
}
  
title::after {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translate(0, -50%);
  width: 5px;
  height: 50px;
  background-color: #1594d1;
}
.team-item__content {
  display: flex;
  align-items: center;
}
.text {
  font-size: 16px;
  color: #6d6d6d;
  font-weight: 500;
  line-height: 26px;
}

.btn {
  background-color: #98ca48;
  display: inline;
  padding: 12px 25px;
  border-radius: 5px;
  font-size: 18px;
  font-weight: 600;
  color: #fff;
  cursor: pointer;
  transition: 0.3s all;
}

.btn:hover {
  background-color: #8cc138;
  transition: 0.3s all;
}

.directions {
  padding: 100px 0;
  position: relative;
  overflow: hidden;
}

.directions-photo {
  position: absolute;
  top: 0;
  right: 0%;
  transform: translate(0%, 0%);
  height: 100%;
  margin-right: -400px;
}

.directions-title {
  font-weight: 700;
  font-size: 25px;
  color: #98ca48;
  position: relative;
  text-align: center;
}

.directions-title::after {
  content: "";
  width: 100px;
  height: 4px;
  position: absolute;
  display: block;
  bottom: -50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ff7f02;
}

.directions-text {
  margin-top: 40px;
}

.directions-text p {
  text-align: center;
  margin-bottom: 10px;
  font-size: 20px;
  font-weight: 500;
}

.directions-items {
  display: -ms-grid;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 15px;
  margin-top: 40px;
}

.directions-item {
  border: 1px solid #f3f1ff;
  border-radius: 5px;
  padding: 20px 25px;
  background: #fff;
  transition: 0.3s;
  cursor: pointer;
}

.directions-item:hover {
  box-shadow: 0 10px 15px rgba(25, 25, 25, 0.1);
  transition: 0.5s !important;
  border-bottom: 5px solid #FF930D !important;
}

.directions-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.directions-icon {
  width: 75px;
  max-width: 75px;
  height: 75px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.directions-icon__color1 {
  background-color: #98ca48;
}

.directions-icon__color2 {
  background-color: #4881d6;
}

.directions-icon__color3 {
  background-color: #ca48c3;
}

.directions-icon__color4 {
  background-color: #ff930d;
}

.directions-icon__color5 {
  background-color: #d65148;
}

.directions-bg {
  width: 60px;
  max-width: 60px;
  height: 60px;
}

.directions-bg img {
  width: 100%;
  max-width: 100%;
  height: 100%;
}

.directions-content {
  margin-top: 40px;
}

.directions-content__title {
  font-weight: 700;
  font-size: 16px;
  color: #98ca48;
  text-align: left;
  margin-bottom: 20px;
}

.directions-content__text {
  font-weight: 500;
  font-size: 14px;
  text-align: left;
  line-height: 24px;
}

.directions-divider {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0%);
}

.footer-top {
  background-color: #28282a;
}

.footer-wrappers {
  padding: 60px 0;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.footer-logo {
  display: flex;
  align-items: center;
}

.footer-logo__photo {
  display: block;
  width: 60px;
  height: 60px;
}

.footer-logo__img {
  width: 100%;
  height: 100%;
}

.footer-logo__text {
  display: flex;
  font-size: 30px;
  font-weight: 700;
  padding-left: 10px;
  color: #1594d1;
}

.footer-logo__text span {
  color: #99c1df;
}

.footer-text {
  color: #fff;
  font-size: 16px;
  margin-top: 20px;
  line-height: 28px;
}

.footer-bottom {
  background-color: #1594d1;
  padding: 30px;
}

.footer-bottom__text {
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  color: #fff;
}

.footer-menu {
  color: #fff;
  font-size: 16px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

.footer-menu__icon {
  margin-right: 10px;
}

.footer-menu:hover {
  color: #d4cccc;
}

.footer-contact {
  display: flex;
  align-items: flex-start;
  margin-bottom: 15px;
  color: #fff;
  font-size: 16px;
  line-height: 24px;
}

.footer-contact:last-child {
  margin-bottom: 0;
}

.footer-icon {
  margin-right: 10px;
  margin-top: 1px;
}

.pl-10 {
  padding-left: 10px;
}

.index1-bg {
  position: relative;
}

.insert {
  display: block;
  position: relative;
  height: 100vh;
}

.insert-bg {
  width: 100%;
  height: 100%;
}

.insert-bg__img {
  width: 100%;
  height: 100%;
}

.insert-bg__img-bottom {
  width: 100%;
  position: absolute;
  bottom: 0;
  z-index: 2;
}

.insert-bg__color {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  /*background-color: #e8e8e8a3;*/
  background-color: #c5c3c38e;
  z-index: 1;
}

.insert-content {
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -55%);
  text-align: center;
  z-index: 2;
}

.insert-content__title {
  color: #1594d1;
  font-size: 60px;
  font-weight: 700;
}

.insert-content__text {
  margin-top: 20px;
  font-size: 22px;
  line-height: 32px;
  font-weight: 400;
}

.onas {
  padding-top: 60px;
  padding-bottom: 40px;
}

.onas-title {
  font-family: "Montserrat";
  text-transform: uppercase;
  font-weight: 700;
  font-size: 25px;
  color: #28282a;
  position: relative;
  padding-left: 15px;
  width: 90%;
  max-width: 90%;
  margin-left: auto;
  margin-right: auto;
}

.onas-title::after {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translate(0, -50%);
  width: 5px;
  height: 50px;
  background-color: #28282a;
}

.onas-items {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 0.8fr 1fr;
  grid-template-columns: 1fr 0.8fr 1fr;
  margin-top: 50px;
}

.onas-blocks {
  display: -ms-grid;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.onas-block {
  border-left: 1px solid #e1e1e1;
  border-top: 1px solid #e1e1e1;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.onas-block__top {
  border-top: none;
}

.onas-block__left {
  border-left: none;
}

.onas-block__number {
  font-size: 50px;
  font-weight: 700;
  text-align: center;
  color: #151515;
}

.onas-block__text {
  text-align: center;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 500;
  margin-top: 10px;
  color: #9b9b9b;
  padding: 0 10px;
}

.onas-item__subtitle {
  font-size: 37px;
  font-weight: 800;
  line-height: 42px;
  padding-left: 20px;
  position: relative;
}

.onas-item__subtitle::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 5px;
  background: #d7d7d7;
}

.onas-item__text {
  margin-top: 25px;
  margin-bottom: 35px;
  color: #9b9b9b;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
}

.onas-item__btn {
  background-color: #1594d1;
  color: #fff;
  font-weight: 500;
  font-size: 18px;
  padding: 15px;
  width: 80%;
  border-radius: 5px;
  cursor: pointer;
  display: block;
}

.onas-item__btn:hover {
  background-color: #1073a3;
  transition: all 0.3s;
}

.onas-item__years {
  color: #1594d1;
  font-size: 310px;
  font-weight: 700;
  margin-top: -50px;
  text-align: center;
}

.partners {
  padding-top: 70px;
  padding-bottom: 70px;
}

.partners-color {
  background-color: #f2fafd;
}

.partners-title {
  font-family: "Montserrat";
  text-transform: uppercase;
  font-weight: 600;
  font-size: 25px;
  color: #1594d1;
  position: relative;
  padding-left: 15px;
  width: 90%;
  max-width: 90%;
  margin-left: auto;
  margin-right: auto;
}

.partners-title::after {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translate(0, -50%);
  width: 5px;
  height: 50px;
  background-color: #1594d1;
}

.partners-items {
  display: -ms-grid;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 40px;
  width: 90%;
  max-width: 90%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
}

.partners-item {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
  border-radius: 5px;
  box-shadow: 0px 1px 3px 2px #2a739733;
}

.partners-item__img {
  width: 40%;
  max-width: 40%;
  padding: 20px 0;
  /*filter: grayscale(1);
  opacity: 0.5;*/
  transition: all 0.35s ease;
  cursor: pointer;
}

.partners-item__text {
  text-align: center;
  padding: 10px;
  font-size: 16px;
  font-weight: 500;
}

.partners-item:hover {
  box-shadow: 0px 1px 3px 2px #2a73975c;
}

.partners-item:hover .partners-item__img {
  filter: grayscale(0);
  opacity: 1;
  transition: all 0.35s ease;
}

.activities {
  position: relative;
  padding: 70px 0;
  padding-bottom: 100px;
}

.activities-title {
  font-family: "Montserrat";
  text-transform: uppercase;
  font-weight: 700;
  font-size: 30px;
  color: #1594d1;
  position: relative;
  text-align: center;
}

.activities-bg {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: -1;
}

.activities-grid {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 0.7fr 1fr;
  grid-template-columns: 0.7fr 1fr;
  grid-gap: 120px;
  width: 100%;
  max-width: 100%;
  margin: auto;
  margin-top: 100px;
}

.activities-items {
  display: -ms-grid;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 25px;
}

.activities-item {
  background-color: #fff;
  box-shadow: 0px 1px 3px 2px #9b9b9b40;
  display: flex;
  justify-content: center;
  padding: 20px;
  border-radius: 10px;
  cursor: pointer;
}

.activities-item:hover {
  box-shadow: 0px 1px 3px 2px #9b9b9b7d;
  transition: all 0.3s;
}

.activities-item__content {
  text-align: center;
}

.activities-item__photo {
  text-align: center;
  padding-bottom: 20px;
}

.activities-item__title {
  text-transform: uppercase;
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  position: relative;
}

.activities-item__title::after {
  content: "";
  display: block;
  width: 50px;
  height: 3px;
  background-color: #9b9b9b;
  position: absolute;
  bottom: -15px;
  left: 50%;
  transform: translate(-50%, 0%);
}

.activities-item__text {
  margin-top: 30px;
  font-size: 16px;
  line-height: 26px;
}

.activities-item__img {
  text-align: center;
  margin: auto;
}

.activities-photo {
  position: relative;
  height: 100%;
  width: 100%;
  border-left: 6px solid #1594d1;
  border-bottom: 6px solid #1594d1;
}

.activities-photo__img {
  width: 100%;
  max-width: 100%;
  height: 100%;
  border-radius: 5px;
}

.activities-photo__img1 {
  position: absolute;
  left: -30px;
  top: -50px;
  height: 53%;
  width: 100%;
  border-radius: 5px;
}

.activities-photo__img2 {
  position: absolute;
  right: -30px;
  bottom: -30px;
  height: 53%;
  width: 100%;
  border-radius: 5px;
}

.technology {
  background-color: #fff;
  padding: 100px 0;
}

.technology-bg {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
}

.technology-title {
  font-family: "Montserrat";
  text-transform: uppercase;
  font-weight: 700;
  font-size: 30px;
  color: #1594d1;
  position: relative;
  text-align: center;
  z-index: 2;
}

.technology-items {
  display: -ms-grid;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  margin-top: 50px;
  position: relative;
  z-index: 2;
}

.technology-item {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px 0;
}

.technology-item__top {
  border-top: none;
}

.technology-item__left {
  border-left: none;
}

.technology-item__img {
  width: 55%;
  max-width: 55%;
  filter: grayscale(1);
  opacity: 0.5;
  transition: all 0.35s ease;
  cursor: pointer;
}

.technology-item:hover .technology-item__img {
  filter: grayscale(0);
  opacity: 1;
  transition: all 0.35s ease;
}

.slick-list {
  overflow: hidden;
}

.slick-slide {
  width: 100%;
  max-width: 100%;
}

.slick-track {
  display: flex;
}

.draft {
  padding: 100px 0;
  background-color: #fff;
}

.draft-title {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 25px;
  color: #1594d1;
  position: relative;
  padding-left: 20px;
}

.draft-title::after {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translate(0, -50%);
  width: 6px;
  height: 50px;
  background-color: #1594d1;
}

.draft-items {
  margin-top: 50px;
  position: relative;
  z-index: 2;
}

.draft-items .slick-next .slick-arrow {
  position: absolute;
}

.draft-items .slick-slide {
  margin: 0 12px;
}

.draft-items .slick-list {
  margin: 0 -12px;
  overflow: static !important;
}

.draft-items .draft-prev {
  position: absolute;
  right: 0;
  z-index: 2;
  top: -90px;
  width: 50px;
  max-width: 50px;
  height: 50px;
  border: 2px solid #1594d1;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 2px;
}

.draft-items .draft-next {
  position: absolute;
  right: 80px;
  z-index: 2;
  top: -90px;
  width: 50px;
  max-width: 50px;
  height: 50px;
  border: 2px solid #1594d1;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 2px;
}

.draft-next:hover {
  background-color: #1594d1;
  transition: all 0.2s;
}

.draft-next:hover>svg {
  fill: #ffffff;
  transition: all 0.2s;
}

.draft-prev:hover {
  background-color: #1594d1;
  transition: all 0.2s;
}

.draft-prev:hover>svg {
  fill: #ffffff;
  transition: all 0.2s;
}

.draft-item {
  background-color: #f2fafd;
  padding: 40px;
  border-radius: 10px;
}

.draft-item__title {
  font-size: 24px;
  font-weight: 700;
  text-indent: 30px;
  color: #443f3f;
}

.draft-item__text {
  text-indent: 30px;
  margin-top: 30px;
  margin-bottom: 40px;
  font-size: 18px;
  line-height: 26px;
}

.draft-item__title1 {
  font-size: 22px;
  font-weight: 600;
  color: #443f3f;
}

.draft-list {
  text-indent: 30px;
  font-size: 16px;
  margin-top: 10px;
  line-height: 24px;
  position: relative;
}

.draft-list__img {
  position: absolute;
  top: 8px;
  left: 15px;
}

.draft-bottom {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 500px;
  grid-template-columns: 1fr 500px;
}

.draft-btns {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.draft-btn {
  background-color: #1594d1;
  font-size: 18px;
  font-weight: 500;
  color: #fff;
  padding: 12px 25px;
  cursor: pointer;
  border-radius: 5px;
}

.draft-btn:hover {
  background-color: #1384ba;
}

.contacts {
  margin-top: 90px;
  padding: 100px 0;
  padding-bottom: 200px;
}

.contacts-title {
  width: 1000px;
  max-width: 100%;
  margin: auto;
  font-family: "Montserrat";
  text-transform: uppercase;
  font-weight: 600;
  font-size: 35px;
  color: #1594d1;
  position: relative;
  padding-left: 20px;
}

.contacts-title::after {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translate(0, -50%);
  width: 6px;
  height: 50px;
  background-color: #1594d1;
}

.contacts-items {
  width: 1000px;
  max-width: 100%;
  margin: auto;
  display: -ms-grid;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin-top: 70px;
}

.contacts-block {
  margin-bottom: 30px;
}

.contacts-block:last-child {
  margin-bottom: 0px;
}

.contacts-block__title {
  font-size: 24px;
  font-weight: 600;
}

.contacts-block__text {
  font-size: 18px;
  line-height: 28px;
  margin-top: 10px;
}

#map {
  width: 100%;
  height: 400px;
}

.about {
  margin-top: 90px;
}

.about-h1 {
  font-family: "Montserrat";
  text-transform: uppercase;
  font-weight: 600;
  font-size: 25px;
  color: #98ca48;
  position: relative;
  padding-left: 20px;
}

.about-h1::after {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translate(0, -50%);
  width: 6px;
  height: 50px;
  background-color: #98ca48;
}

.about-items {
  display: grid;
  grid-template-columns: 0.8fr 1fr;
  grid-gap: 40px;
}

.about-title {
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 25px;
  color: #1594d1;
  position: relative;
  padding-left: 15px;
}

.about-title::after {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translate(0, -50%);
  width: 4px;
  height: 32px;
  background-color: #1594d1;
}

.about-suptitle {
  font-family: "Montserrat";
  font-weight: 700;
  font-size: 30px;
  color: #28282a;
  position: relative;
  margin-top: 30px;
  padding-right: 30px;
}

.about-text {
  font-size: 16px;
  line-height: 26px;
  text-indent: 30px;
  margin-top: 20px;
}

.about-photo {
  width: 100%;
  height: 580px;
  position: relative;
}

.about-photo__img1 {
  position: absolute;
  top: 0;
  right: 0;
  width: 95%;
  height: 80%;
}

.about-photo__img2 {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 55%;
  height: 40%;
}

.about-photo__block {
  width: 92%;
  height: 92%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid #fff;
}

.about-photo__year {
  position: absolute;
  bottom: 4%;
  right: 4%;
  background-color: #1594d1;
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  padding: 12px 30px;
}

.about-content {
  padding-left: 20px;
}

.about-childrens {
  display: -ms-grid;
  display: grid;
  width: 100%;
  max-width: 100%;
  margin: auto;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
  margin-top: 40px;
}

.about-children {
  display: flex;
  padding: 10px;
  cursor: pointer;
}

.about-children__icon {
  width: 22%;
  min-width: 22%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin-right: 1%;
}

.about-children__conten {
  display: flex;
  align-items: center;
  justify-content: center;
}

.about-children__title {
  font-size: 18px;
  font-weight: 600;
}

.about-children__text {
  margin-top: 5px;
  font-size: 16px;
  line-height: 24px;
}

.about-children:hover {
  box-shadow: 0px 1px 4px 1px #5252563b;
  border-radius: 10px;
}

.my {
  padding: 100px 0;
  position: relative;
}

.sector {
  padding: 100px 0;
  padding-bottom: 50px;
  background-color: #f2fafd;
}

.sector-title {
  font-weight: 700;
  font-size: 25px;
  color: #1594d1;
  position: relative;
  padding-left: 15px;
}

.sector-title::after {
  content: "";
  width: 4px;
  height: 40px;
  position: absolute;
  display: block;
  left: 0%;
  top: 50%;
  transform: translateY(-50%);
  background-color: #1594d1;
}

.sector-text {
  font-size: 20px;
  font-weight: 700;
  line-height: 29px;
  margin-top: 50px;
  text-indent: 30px;
  color: #28282a;
}

.sector-items {
  display: -ms-grid;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin-top: 50px;
}

.sector-item {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 50px;
}

.sector-item__text {
  text-align: center;
  margin-top: 30px;
  color: #28282a;
  font-size: 18px;
  font-weight: 600;
}

.team {
  padding: 100px 0;
  position: relative;
}

.team-title {
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 25px;
  color: #28282a;
  position: relative;
  padding-left: 15px;
}

.team-title::after {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translate(0, -50%);
  width: 4px;
  height: 32px;
  background-color: #28282a;
}

.team-items {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin-top: 50px;
}

.team-item {
  display: flex;
  margin-bottom: 30px;
}

.team-item__photo {
  margin-right: 15px;
}

.team-item__title {
  font-size: 18px;
  font-weight: 600;
}

.team-item__text {
  margin-top: 10px;
  font-size: 16px;
}

.pt-50 {
  padding-top: 50px;
}

.pt-100 {
  padding-top: 100px;
}

.projects {
  margin-top: 90px;
  padding-bottom: 100px;
}

.projects-wrapper {
  position: relative;
}

.projects-wrapper__color {
  background-color: #f2fafd;
}

.projects-title {
  font-family: "Montserrat";
  text-transform: uppercase;
  font-weight: 600;
  font-size: 35px;
  color: #1594d1;
  position: relative;
  padding-left: 20px;
}

.projects-title::after {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translate(0, -50%);
  width: 6px;
  height: 50px;
  background-color: #1594d1;
}

.projects-items {
  margin-top: 50px;
}

.projects-item {
  padding: 40px;
  padding-bottom: 60px;
  position: relative;
}

.projects-item__title {
  font-size: 24px;
  font-weight: 600;
  text-indent: 30px;
  margin-bottom: 30px;
}

.projects-item__text {
  text-indent: 30px;
  margin-bottom: 40px;
  font-size: 18px;
  line-height: 28px;
}

.projects-item__title1 {
  font-size: 22px;
  font-weight: 600;
}

.projects-lists {
  margin-bottom: 20px;
}

.projects-list {
  text-indent: 30px;
  margin-top: 10px;
  font-size: 16px;
  line-height: 26px;
  position: relative;
}

.projects-list__icon {
  position: absolute;
  top: 7px;
  left: 14px;
}

.projects-btn {
  background-color: #1594d1;
  font-size: 18px;
  font-weight: 500;
  color: #fff;
  padding: 12px 25px;
  cursor: pointer;
  border-radius: 5px;
  display: inline-block;
  margin-top: 30px;
}

.projects-btn:hover {
  background-color: #1384ba;
}

.projects-line {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0%);
}

.projects-cubic__left {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.projects-cubic__right {
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
}

body {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
}

.container {
  width: 100%;
  max-width: 100%;
  margin: auto;
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1300px) {
  .container {
    max-width: 1200px;
  }
}

@media (max-width: 1200px) {
  .container {
    max-width: 1060px;
    padding-left: 15px;
    padding-right: 15px;
  }

  .directions-item {
    padding: 15px;
  }

  .directions-icon {
    width: 65px;
    max-width: 65px;
    height: 65px;
  }

  .directions-bg {
    width: 50px;
    max-width: 50px;
    height: 50px;
  }

  .insert {
    height: 90vh;
  }

  .onas-item__years {
    font-size: 280px;
  }

  .activities-grid {
    width: 100%;
    max-width: 100%;
  }

  .footer-wrappers {
    grid-gap: 20px;
  }
}
.active-menu {
  display: flex !important;
}

.draft-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.draft-tag {
  display: flex;
}

.draft-prev {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #1594d1;
  width: 40px;
  height: 40px;
  cursor: pointer;
  border-radius: 2px;
}

.draft-next {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  border: 1px solid #1594d1;
  width: 40px;
  height: 40px;
  cursor: pointer;
  border-radius: 2px;
}

.draft-item__none {
  display: none !important;
  transition: all 0.3s;
}

.draft-item__active {
  display: block !important;
  transition: all 0.3s;
}

.header-language {
  color: #fff;
  position: relative;
  width: 70px;
  margin-left: 50px;
}

.header-language__current {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  color: #545454;
  width: 70px;
  max-width: 70px;
}

.header-language__current:hover {
  color: #564e4e;
} 

.header-language__menus {
  position: absolute;
  width: 100%;
  margin-top: 10px;
  background-color: #28282a;
  border-radius: 5px;
}

.header-language__menu {
  border-bottom: 1px solid #fff;
  padding: 10px;
  cursor: pointer;
  color: #fff;
  background-color: #28282a;
}

.header-language__menu:hover {
  background-color: #434344;
}

.header-language__menu-icon {
  transform: rotate(180deg);
  transition: all 0.3s;
}

.header-language__menu:last-child {
  margin-bottom: 0px;
  border-bottom: none;
}

.project-list__title {
  font-family: "Montserrat";
  text-transform: uppercase;
  font-weight: 600;
  font-size: 20px;
  color: #1594d1;
  position: relative;
  padding-left: 20px;
}

.project-list__title::after {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translate(0, -50%);
  width: 5px;
  height: 50px;
  background-color: #1594d1;
}

.projects-item__text {
  text-indent: 30px;
  margin-bottom: 40px;
  font-size: 18px;
  line-height: 28px;
}

.projects-item__title1 {
  font-size: 22px;
  font-weight: 600;
}

.projects-lists {
  margin-bottom: 20px;
}

.projects-list {
  text-indent: 30px;
  margin-top: 10px;
  font-size: 16px;
  line-height: 26px;
}

.projects-btn {
  background-color: #ff930d;
  font-size: 18px;
  font-weight: 500;
  color: #fff;
  padding: 12px 25px;
  cursor: pointer;
  border-radius: 5px;
  display: inline-block;
  margin-top: 30px;
}

.projects-btn:hover {
  background-color: #f38600;
}

.projects-line {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0%);
}

.projects-cubic__left {
  position: absolute;
  top: 0;
  left: 0;
}

.projects-cubic__right {
  position: absolute;
  top: 0;
  right: 0;
}

.projects2-items_left {
  display: grid;
  grid-template-columns: 1fr 300px;
  margin-top: 20px;
  margin-bottom: 40px;
  grid-gap: 30px;
}

.projects2-items_right {
  display: grid;
  grid-template-columns: 300px 1fr;
  margin-top: 20px;
  grid-gap: 30px;
}

.projects1-block2_wrapper-photo {
  width: 100%;
  max-width: 100%;
}

.projects1-block2_wrapper-img {
  margin: auto;
}

.projects1-block2_wrapper-text {
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  margin-top: 10px;
}

.pb-15 {
  padding-bottom: 15px;
}

.pt-20 {
  padding-top: 20px;
}

.mt-20 {
  margin-top: 20px;
}

.portfolio2-title {
  font-size: 22px;
  font-weight: 600;
  color: #1594d1;
  margin-top: 40px;
  text-align: center;
}

.portfolio2-photo {
  width: 100%;
  max-width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
}

.portfolio3-photo {
  width: 80%;
  max-width: 80%;
  margin: auto;
}

.portfolio3-photo__img {
  width: 100%;
  max-width: 100%;
  height: 100%;
}

.portfolio3-title {
  text-align: center;
  font-size: 22px;
  font-weight: 600;
  margin: 20px 0;
}

.portfolio3-photo__img {
  width: 100%;
  max-width: 100%;
}

.projects-list__items {
  margin-left: 30px;
}

.header-menu__active {
  border-bottom: 2px solid #1594d1;
  padding-bottom: 4px;
}

.projectPage {
  padding: 100px 0;
}

@media (max-width: 1300px) {
  .header-phone {
    display: none;
  }
}

@media (max-width: 992px) {
  .projects-wrapper.pt-100 {
    padding-top: 20px;
  }

  .partners {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .activities-grid {
    margin-top: 40px;
  }

  .partners-items {
    max-width: 100%;
    max-width: 100%;
    grid-gap: 15px;
  }

  .partners-item__img {
    padding-bottom: 10px;
  }

  .active-menu {
    display: block !important;
  } 

  .header-menus {
    position: absolute;
    background-color: #1594d1;
    top: 100px;
    right: 0;
    width: 50%;
    max-width: 50%;
    margin: 0;
    display: none;
    padding: 10px 0;
  }

  .insert {
    height: 75vh;
  }

  .header-menu {
    padding: 10px 20px;
    display: block;
    color: #fff;
  }

  .header-phone {
    width: 100%;
    max-width: 100%;
    justify-content: center;
  }

  .header-language {
    margin-right: 30px;
  }

  .phone-menu {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .directions-items {
    grid-template-columns: repeat(2, 1fr);
  }

  .directions-item:nth-child(5) {
    -ms-grid-column-span: 2;
    grid-column: span 2;
    width: 50%;
    max-width: 50%;
    margin: auto;
  }

  .insert-content__title {
    font-size: 50px;
  }

  .onas-items {
    -ms-grid-columns: 1fr 0.8fr;
    grid-template-columns: 1fr 0.8fr;
    /* grid-column: span 2; */
  }

  .onas-item:nth-child(3) {
    -ms-grid-column-span: 2;
    grid-column: span 2;
    margin-top: 50px;
  }

  .activities-grid {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .activities-items {
    -ms-grid-row: 1;
    grid-row: 1;
    width: 90%;
    max-width: 90%;
    margin: auto;
  }

  .activities-photo {
    height: 583px;
    width: 70%;
    max-width: 70%;
    margin: auto;
  }

  .technology {
    padding: 50px 0;
  }

  .technology-bg {
    display: none;
  }

  .draft {
    padding: 50px 0;
  }

  .draft-bottom {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .draft-btns {
    margin-top: 20px;
  }

  .footer-wrappers {
    grid-template-columns: repeat(2, 1fr);
    width: 90%;
    max-width: 90%;
    margin: auto;
  }

  .footer-menu {
    justify-content: flex-end;
  }

  .footer-wrapper:nth-child(3) {
    -ms-grid-column-span: 2;
    grid-column: span 2;
  }

  .footer-contacts {
    margin-top: 30px;
  }

  .contacts {
    padding-bottom: 100px;
  }

  .contacts-title {
    width: 90%;
    max-width: 90%;
    margin: auto;
  }

  .contacts-items {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    margin-top: 50px;
  }

  .contacts-item {
    width: 90%;
    max-width: 90%;
    margin: auto;
  }

  .contacts-map {
    margin-top: 30px;
  }

  .about-items {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .about-content {
    grid-row: -1;
  }

  .about-photo {
    width: 80%;
    max-width: 80%;
    margin: auto;
  }

  .sector {
    padding: 75px 0;
  }

  .sector-text {
    font-size: 18px;
    margin-top: 30px;
  }

  .team-items {
    grid-gap: 20px;
  }

  .pt-50 {
    padding-top: 30px;
  }

  .projects {
    padding: 80px 0;
  }

  .projects-title {
    font-size: 30px;
  }

  .projects-item {
    padding: 10px;
    padding-bottom: 30px;
  }

  .projects-item__title {
    font-size: 22px;
    line-height: 32px;
    margin-bottom: 20px;
  }

  .projects-item__title1 {
    font-size: 20px;
    line-height: 30px;
  }

  .projects-item__text {
    font-size: 16px;
    line-height: 26px;
    margin-bottom: 20px;
  }

  .projects-btn {
    margin-top: 10px;
    font-size: 16px;
  }
}

@media (max-width: 768px) {
  .header-phone {
    width: 50%;
    max-width: 50%;
  }

  .projects-wrapper.pt-100 {
    padding-top: 10px;
  }

  .footer-wrappers {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .footer-menu {
    justify-content: flex-start;
  }

  .footer-wrapper:nth-child(3) {
    -ms-grid-column-span: 1;
    grid-column: span 1;
  }

  .footer-menus {
    margin-top: 10px;
  }

  .footer-contacts {
    margin-top: 10px;
  }

  .onas-items {
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
  }

  .onas-item__years {
    font-size: 250px;
  }

  .onas-item__subtitle {
    font-size: 30px;
    line-height: 37px;
  }

  .partners {
    padding: 40px 0;
  }

  .partners-items {
    max-width: 90%;
    grid-gap: 20px;
    grid-template-columns: 50% 50%;
  }

  .activities {
    padding: 50px 0;
  }

  .activities-grid {
    margin-top: 60px;
  }

  .activities-items {
    grid-gap: 25px;
  }

  .about-items {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    grid-gap: 20px;
  }

  .about-photo {
    width: 90%;
    max-width: 90%;
    height: 610px;
  }

  .header-phone {
    display: none;
  }

  .projects2-items_left {
    grid-template-columns: 1fr;
  }

  .projects2-items_right {
    grid-template-columns: 1fr;
  }
  .projects1-block2_wrapper-photo {
    grid-row: 2;
  }
  
  .activities-items {
    width: 100%;
    max-width: 100%;
  }
  .technology-item {
    padding: 20px 0;
  }
  .about-childrens {
    grid-template-columns: 1fr;
    width: 80%;
    max-width: 80%;
    margin-top: 20px;
  }
  .about-children__icon {
    width: 20%;
    max-width: 20%;
  }
  .projects-cubic__left {
    display: none;
  }
}

@media (max-width: 576px) {
  .projects-wrapper.pt-100 {
    padding-top: 0px;
  }

  .directions {
    padding: 80px 0;
  }

  .header-language {
    display: block;
    width: 55px;
    max-width: 55px;
    margin-right: 25px;
    margin-left: 0px;
  }

  .header-language__current {
    width: 55px;
    max-width: 55px;
  }

  .header-language__menu {
    font-size: 14px;
    padding: 8px;
  }

  .directions-text p {
    font-size: 18px;
    text-indent: 30px;
    line-height: 28px;
    text-align: left;
  }

  .directions-items {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    width: 90%;
    max-width: 90%;
    margin: auto;
    margin-top: 30px;
  }

  .directions-item {
    padding: 20px 25px;
  }

  .directions-item:nth-child(5) {
    -ms-grid-column-span: 1;
    grid-column: span 1;
    width: 100%;
    max-width: 100%;
  }

  .directions-content__title {
    font-size: 18px;
    color: #98ca48;
    margin-bottom: 15px;
  }

  .onas-items {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .onas-item:nth-child(3) {
    -ms-grid-column-span: 1;
    grid-column: span 1;
  }

  .activities-items {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .activities-photo {
    width: 85%;
    max-width: 85%;
  }

  .technology-items {
    margin-top: 30px;
  }

  .technology-item {
    padding: 15px 0;
  }

  .about-suptitle {
    font-size: 25px;
  }

  .about-childrens {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .about-photo {
    width: 100%;
    max-width: 100%;
    height: 550px;
  }

  .about-content {
    padding-left: 0;
  }

  .sector-items {
    grid-template-columns: repeat(2, 1fr);
  }

  .team-items {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }
  .projects-cubic__left {
    display: none;
  }
  .activities-items {
    width: 90%;
    max-width: 90%;
  }
  .activities-grid {
    margin-top: 40px;
  }
}

@media (max-width: 480px) {
  .header-phone {
    display: none;
  }
  .partners-items {
    max-width: 100%;
    grid-gap: 15px;
    grid-template-columns: 50% 50%;
  }

  .portfolio3-photo {
    width: 100% !important;
    max-width: 100% !important;
  }

  .header-logo__photo {
    width: 40px;
    height: 40px;
  }

  .header-logo__text {
    font-size: 25px;
    padding-left: 5px;
  } 

  .header-menus {
    width: 100%;
    max-width: 100%;
    top: 85px;
  }

  .header-menu {
    padding-bottom: 10px;
    padding-left: 20px;
    padding-right: 20px;
    display: block;
    color: #fff;
  }

  .btn {
    font-size: 16px;
  }

  .directions {
    padding: 60px 0;
  }

  .insert-content {
    width: 90%;
    max-width: 90%;
  }

  .directions-title {
    font-size: 22px;
  }

  .directions-title::after {
    bottom: -30%;
  }

  .insert-content__title {
    font-size: 40px;
  }

  .insert-content__text {
    font-size: 18px;
    line-height: 28px;
  }

  .onas-item__years {
    font-size: 230px;
  }

  .onas-item__text {
    margin-top: 25px;
    margin-bottom: 35px;
  }

  .draft-items .draft-next {
    width: 40px;
    height: 40px;
    max-width: 40px;
    right: 50px;
  }

  .draft-items .draft-prev {
    width: 40px;
    height: 40px;
    max-width: 40px;
  }

  .draft-item {
    padding: 20px;
  }

  .draft-item__title {
    font-size: 20px;
  }

  .draft-item__title1 {
    font-size: 20px;
  }

  .draft-item__text {
    font-size: 16px;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .draft-btn {
    font-size: 16px;
  }

  .activities-title {
    font-size: 25px;
    padding-left: 20px;
    padding-right: 20px;
  }

  .activities-grid {
    margin-top: 40px;
  }

  .activities-photo {
    height: 540px;
  }

  .technology-title {
    font-size: 25px;
    padding: 0 20px;
  }

  .technology-item {
    padding: 10px 0;
  }

  .technology-item__img {
    width: 65%;
    max-width: 65%;
  }

  .technology-items {
    grid-template-columns: repeat(4, 1fr);
  }

  .technology-item:nth-last-child(-n+2) {
    display: none;
  }

  .technology-item:last-child {
    display: none;
  }

  .contacts {
    padding: 60px 0;
  }

  .projects {
    padding: 60px 0;
  }
  
  .my {
    padding-top: 60px;
  }

  .contacts-title {
    font-size: 22px;
    padding-left: 12px;
  }

  .contacts-title::after {
    width: 5px;
    height: 40px;
  }

  .contacts-map {
    margin-top: 30px;
  }

  .contacts-block__title {
    font-size: 22px;
  }

  .projects .projects-cubic__left {
    display: none;
  }

  .projects .projects-cubic__right {
    display: none;
  }

  .about-photo {
    width: 100%;
    max-width: 100%;
    height: 480px;
  }

  .sector-item {
    margin-bottom: 35px;
  }

  .sector-item__text {
    font-size: 16px;
  }

  .projects-title {
    font-size: 22px;
    padding-left: 15px;
  }

  .projects-title::after {
    width: 5px;
    height: 40px;
  }
  
  .projects-wrapper {
    padding-bottom: 20px;
  }

  .projects-item {
    padding-top: 30px;
    padding-bottom: 40px;
  }

  .projects-item__title {
    font-size: 20px;
    line-height: 30px;
  }
  .about-childrens {
    width: 100%;
    max-width: 100%;
    grid-gap: 0;
  }
  .about-photo__img2 {
    width: 60%;
    height: 45%;
  }
}